import {AbstractControl, FormGroup} from '@angular/forms';

export class FormHelper {
    static readonly NAME_FIELD_MIN_LENGTH = 2;
    static readonly NAME_FIELD_MAX_LENGTH = 100;
    static readonly NAME_LONG_FIELD_MAX_LENGTH = 200;
    static readonly NAME_REGEX_VALIDATOR = "^[\\w'\\-,.][^0-9_!¡?÷?¿/\\\\+=@#$%ˆ&*(){}|~<>;:[\\]]{1,}$";
    static readonly ACTOR_FIELD_MAX_LENGTH = 200;
    static readonly DESCRIPTION_FIELD_MIN_LENGTH = 2;
    static readonly DESCRIPTION_FIELD_MAX_LENGTH = 1500;
    static readonly NOTE_FIELD_MIN_LENGTH = 5;
    static readonly NOTE_FIELD_MAX_LENGTH = 1500;
    static readonly SYMBOLS_ONLY_REGEX: RegExp = /^[^\w\s]+$/;
    static readonly NOT_SYMBOLS_ONLY_REGEX: RegExp = /^(?:(?!^[^\w\s]+$).)+$/
    static readonly regexLowerToUpper: RegExp = /([a-z])([A-Z])/g
    static readonly regexUpperToLower: RegExp = /([A-Z])([A-Z][a-z])/g;
    static readonly PAYMENT_TERMS_FIELD_MAX_LENGTH = 1500;
    static readonly CODE_FIELD_MIN_LENGTH = 2;
    static readonly CODE_FIELD_MAX_LENGTH = 10;
    static readonly WEBSITE_FIELD_MIN_LENGTH = 2;
    static readonly WEBSITE_FIELD_MAX_LENGTH = 100;
    static readonly ACCOUNT_NUMBER_FIELD_MIN_LENGTH = 6;
    static readonly ACCOUNT_NUMBER_FIELD_MAX_LENGTH = 35;
    static readonly TAX_IDENTIFICATION_NUMBER_MIN_LENGTH = 6;
    static readonly TAX_IDENTIFICATION_NUMBER_MAX_LENGTH = 15;
    static readonly BUSINESS_IDENTIFICATION_NUMBER_MIN_LENGTH = 6;
    static readonly BUSINESS_IDENTIFICATION_NUMBER_MAX_LENGTH = 15;
    static readonly LONG_NAME_FIELD_NAME_FIELD_MAX_LENGTH = 150;
    static readonly ADDRESS_FIELD_MIN_LENGTH = 2;
    static readonly ADDRESS_FIELD_MAX_LENGTH = 500;
    static readonly ALPHA_NUMERIC_ONLY_VALIDATOR: RegExp = /^(?=.*[a-zA-Z])[a-zA-Z0-9 ]+$/;
    static readonly ALPHANUMERIC_WITH_SPACES_AND_DASH: RegExp = /^[A-Za-z0-9\s-]+$/;
    static readonly ALPHANUMERIC_WITH_SPACES_AND_DASH_AND_SLASH: RegExp = /^[A-Za-z0-9\s/\\-]+$/;
    static readonly ALPHANUMERIC_WITH_HYPHEN_SLASH_UNDERSCORE_PIPE: RegExp =
        /^[a-zA-Z0-9][a-zA-Z0-9\-/_|\\]*$/;
    static readonly BYTES_IN_GB = 1024 * 1024 * 1024;
    static readonly ALPHANUMERIC_WITH_HYPHEN_SLASH_UNDERSCORE_PIPE_SPACE_NOT_CONSECUTIVE: RegExp =
        /^[a-zA-Z0-9][a-zA-Z0-9\-/_|\\ ]*(?<!\s\s)$/;
    static readonly ALPHANUMERIC_WITH_HYPHEN_HASH_DOT_SLASH_UNDERSCORE_PIPE_SPACE_NOT_CONSECUTIVE_COMMA_AMPERSAND_BRACES_COLON: RegExp =
        /^[a-zA-Z0-9][a-zA-Z0-9\-/_|\\#.,& ()[\]{}:]*(?<!\s\s)$/;
    static readonly CURRENCY_AMOUNT_REGEX: RegExp = /^[0-9]+([,.][0-9]+)*$/;
    static readonly STARTS_WITH_ALPHANUMERIC_ACCEPTS_DASH_UNDERSCORE: RegExp =
        /^[A-Za-z0-9][A-Za-z0-9_-]*$/;
    static readonly WEBSITE_REGEX: RegExp =
        /^(https?|ftp):\/\/(www\.)?([a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+|(\d{1,3}\.){3}\d{1,3})(:\d+)?(\/[^/]*)*$/;
    static readonly HUMAN_NAME_REGEX = /^[\\w'\-,.][^0-9_!¡?÷¿\/\\+=@#$%ˆ&*(){}|~<>;:[]]+$/;
    static readonly NAME_REGEX = "^[A-Za-z0-9][A-Za-z][._ -'A-Za-z0-9s]*$";
    static readonly NIGERIAN_REGISTRATION_NUMBER_REGEX = '^[0-9]{3,10}$';
    static readonly NAME_NOT_STARTING_WITH_NUMBER_REGEX = "^[A-Za-z][._ -'A-Za-z0-9s]*$";
    static readonly POSITIVE_NUMBER_REGEX = '^[0-9]+(?:\\.[0-9]+)?$';
    static readonly NUMBER_ONLY_REGEX = /[0-9]+/;
    static readonly NUMBER_ONLY_REGEX2 = /^\d+$/;
    static readonly SWIFT_CODE_MIN_LENGTH = 8;
    static readonly SWIFT_CODE_MAX_LENGTH = 11;
    static readonly ROUTING_NUMBER_MIN_LENGTH = 8;
    static readonly ROUTING_NUMBER_MAX_LENGTH = 11;
    static readonly DIGIT_HYPHEN_WITH_AT_LEAST_DIGIT_AND_NO_CONSECUTIVE_HYPHEN: RegExp =
        /^(?=.*\d)[\d-]+(?<!--)$/;
    static readonly ALPHANUMERIC_ONLY: RegExp = /^[a-zA-Z0-9]+$/;
    static LEARNING_FIELD_MIN_LENGTH = 2;
    static LEARNING_FIELD_MAX_LENGTH = 1500;
    static readonly EMAIL_PATTERN: RegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    static readonly EMAIL: RegExp = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
    static readonly IP_ADDRESS: RegExp = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    static readonly URL_PATTERN: RegExp = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w.-]*)*\/?$/;

    static formatAmount(value: string, formControl: AbstractControl): void {
        // Remove non-numeric characters and limit to one decimal place with a maximum of two characters after the decimal point
        let formattedValue = value?.replace(/[^\d.]/g, '');
        const decimalIndex = formattedValue.indexOf('.');

        // If there is a decimal point and more than two characters after it, truncate the extra characters
        if (decimalIndex !== -1) {
            formattedValue = formattedValue.substring(0, decimalIndex + 3);
        }

        // Add commas every three digits before the decimal point
        formattedValue = formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        // Update the form control value
        formControl.setValue(formattedValue, {emitEvent: false});
    }

    static getFormattedAmount(value: string): string {
        // Remove non-numeric characters and limit to one decimal place with a maximum of two characters after the decimal point
        let formattedValue = value?.replace(/[^\d.]/g, '');
        const decimalIndex = formattedValue.indexOf('.');

        // If there is a decimal point and more than two characters after it, truncate the extra characters
        if (decimalIndex !== -1) {
            formattedValue = formattedValue.substring(0, decimalIndex + 3);
        }

        // Add commas every three digits before the decimal point
        return formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    static stripFirstNonBlank(value: string, formControl: AbstractControl): void {
        const firstNonBlankIndex = value.search(/\S/);

        if (firstNonBlankIndex !== -1) {
            formControl?.setValue(firstNonBlankIndex, {emitEvent: false});
        }
    }

    static stripLeadingSpaceWhenFirstNotBlank(value: string, formControl: AbstractControl): void {
        const firstNonBlankIndex = value.search(/\S/);

        if (firstNonBlankIndex !== -1) {
            // There is at least one non-blank character, strip leading spaces
            const strippedValue = value.substring(firstNonBlankIndex);
            formControl?.patchValue(strippedValue, {emitEvent: false}); // Prevent infinite loop
        }
    }

    static removeBlanks(value: string, formControl: AbstractControl): void {
        formControl.setValue(value?.replace(/\s/g, ''), {emitEvent: false});
    }

    static removeInvalidDate(event): void {
        console.log(event);
    }

    static clearValidatorsAndUpdateValues(controlNames: string[], form: FormGroup): void {
        controlNames.forEach((controlName) => {
            // Check if the control exists in the form group
            if (form.contains(controlName)) {
                const control: AbstractControl | null = form.get(controlName);

                // Clear validators
                control?.clearValidators();
                control?.updateValueAndValidity();
            }
        });
    }
}
