import { PermissionType } from '../account/permission-type.enum';
import { UserAccount } from '../account/user-account.model';
import { Account } from '../account/account.model';
import { RolePojo } from '../account/role.pojo.model';
import { PortalAccountRole } from '../account/portal-account-type.enum';


export class User {
  public accounts!: UserAccount[];
  public email!: string;
  public firstName!: string;
  public lastName!: string;
  public displayName: string;
  public phone!: string;
  public requiresPasswordUpdate!: boolean;
  public userId!: string;
  public id!: number;
  public status!: string;
  public username!: string;
  public accountPojo!: Account;
  public fullAddress!: string;
  public nin!: string;
  public displayPictureId: number;
  public signatureId: number;
  public designation: string;
  public mobileNumber: string;

  constructor(data: any) {
    Object.assign(this, data);
    if (data.accounts) {
      this.accounts = data.accounts;
    }
    if (data.accountPojo) {
      this.accountPojo = new Account(data.accountPojo);
    }
  }

  public permissions(): string[] {
    if (!this.accountPojo) {
      return [];
    }
    const roles: RolePojo[] = this.accountPojo.roles;
    let permits: PermissionType[] = [];
    roles.forEach((r) => {
      const p: PermissionType[] = r.permissions;
      permits = permits.concat(...p);
    });
    return permits.filter((v, i) => permits.indexOf(v) === i);
  }

  public hasPermission(permissionName: string | PermissionType): boolean {
    return (this.permissions() || []).filter((it: string) => it === permissionName).length > 0;
  }

  public getRole(roles: RolePojo[]): RolePojo | undefined {
    return roles.find((r) => {
      let role: RolePojo | null = null;

      switch (r.name) {
        case PortalAccountRole.ADMIN:
          role = r;
          break;

        case PortalAccountRole.MANAGEMENT:
          role = r;
          break;

        case PortalAccountRole.FINANCE_OFFICER:
          role = r;
          break;

        case PortalAccountRole.ASA_ADMIN:
          role = r;
          break;

        case PortalAccountRole.ASA_APPROVER:
          role = r;
          break;

        case PortalAccountRole.SUPPORT:
          role = r;
          break;
      }

      return role;
    });
  }

  public hasRole(roleName: string): boolean {
    const accounts: UserAccount[] = this.accounts.filter((acct: any) => {
      return acct.roles.filter((it: string) => it === roleName).length;
    });
    if (!accounts.length) {
      return false;
    }
    return true;
  }

  public isPortalAccountType(type: string): boolean {
    const accounts: UserAccount[] = this.accounts.filter((acct: any) => {
      return acct.accountType.toUpperCase() === type.toUpperCase();
    });
    if (!accounts.length) {
      return false;
    }
    return true;
  }
}
