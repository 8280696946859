/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PortalUserPojo } from './portal-user-pojo';


export interface BlockerPojo { 
    id?: number;
    identifier?: string;
    description?: string;
    resolutionStatus?: BlockerPojo.ResolutionStatusEnum;
    wasteType?: BlockerPojo.WasteTypeEnum;
    externalSystemIssueUrl?: string;
    resolvedAt?: string;
    resolvedBy?: PortalUserPojo;
    portalAccountId?: number;
    blockerCategoryId?: number;
    blockerCategoryName?: string;
    affectedDomainEntityType?: BlockerPojo.AffectedDomainEntityTypeEnum;
    affectedDomainEntityId?: number;
    influencerPortalUserId?: number;
    influencerPortalUser?: PortalUserPojo;
}
export namespace BlockerPojo {
    export type ResolutionStatusEnum = 'PENDING' | 'IN_PROGRESS' | 'RESOLVED' | 'CANCELLED';
    export const ResolutionStatusEnum = {
        Pending: 'PENDING' as ResolutionStatusEnum,
        InProgress: 'IN_PROGRESS' as ResolutionStatusEnum,
        Resolved: 'RESOLVED' as ResolutionStatusEnum,
        Cancelled: 'CANCELLED' as ResolutionStatusEnum
    };
    export type WasteTypeEnum = 'TIME' | 'INVENTORY' | 'MOTION' | 'WAITING' | 'OVER_PRODUCTION' | 'OVER_PROCESSING' | 'DEFECT';
    export const WasteTypeEnum = {
        Time: 'TIME' as WasteTypeEnum,
        Inventory: 'INVENTORY' as WasteTypeEnum,
        Motion: 'MOTION' as WasteTypeEnum,
        Waiting: 'WAITING' as WasteTypeEnum,
        OverProduction: 'OVER_PRODUCTION' as WasteTypeEnum,
        OverProcessing: 'OVER_PROCESSING' as WasteTypeEnum,
        Defect: 'DEFECT' as WasteTypeEnum
    };
    export type AffectedDomainEntityTypeEnum = 'MODULE' | 'CUSTOMER_VALUE' | 'PRODUCTION_UNIT' | 'DELEGATION' | 'TICKET' | 'ACTIVITY';
    export const AffectedDomainEntityTypeEnum = {
        Module: 'MODULE' as AffectedDomainEntityTypeEnum,
        CustomerValue: 'CUSTOMER_VALUE' as AffectedDomainEntityTypeEnum,
        ProductionUnit: 'PRODUCTION_UNIT' as AffectedDomainEntityTypeEnum,
        Delegation: 'DELEGATION' as AffectedDomainEntityTypeEnum,
        Ticket: 'TICKET' as AffectedDomainEntityTypeEnum,
        Activity: 'ACTIVITY' as AffectedDomainEntityTypeEnum
    };
}


