/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CustomerContactPojo } from './customer-contact-pojo';
import { NameIdPojo } from './name-id-pojo';


export interface CustomerOrganisationPojo { 
    id?: number;
    customerOrganisationName?: string;
    customerOperationalAddress?: string;
    customerRegistrationNumber?: string;
    customerWebsite?: string;
    platformPojo?: Array<NameIdPojo>;
    countryName?: string;
    stateName?: string;
    businessType?: NameIdPojo;
    attachmentCount?: number;
    customerContactPojo?: Array<CustomerContactPojo>;
    createdAt?: string;
}

