/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ProcessStageDTO { 
    id?: number;
    name: string;
    code: string;
    icon: string;
    ordinal?: number;
    description: string;
    definitionScore?: number;
    versionNumber?: number;
    versionNumberDisplayName?: string;
    reviewStatus?: ProcessStageDTO.ReviewStatusEnum;
    iconName?: boolean;
}
export namespace ProcessStageDTO {
    export type ReviewStatusEnum = 'APPROVED' | 'PENDING' | 'DISAPPROVED';
    export const ReviewStatusEnum = {
        Approved: 'APPROVED' as ReviewStatusEnum,
        Pending: 'PENDING' as ReviewStatusEnum,
        Disapproved: 'DISAPPROVED' as ReviewStatusEnum
    };
}


