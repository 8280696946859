<div
        [ngStyle]="{ left: toggled ? '0' : '-280px' }"
        class="side-nav side">
    <div class="d-flex logo justify-content-center align-items-center">
        <img [routerLink]="'/'" class="pointer" src="assets/img/cfs-logo.svg" alt="logo"/>
    </div>

    <div>
        <div class="navs">

            <div class="link-wrapper mb-3">
                <div class="link"
                     (click)="closeToggle()"
                     [routerLink]="['/dashboard']"
                     [class.router-link-active]="isDashboardRoute">
                    <i class="iconify" data-inline="false" data-icon="material-symbols:dashboard"></i>
                    <a  [routerLink]="['/dashboard']">Dashboard</a>
                </div>
            </div>
            <div class="link-wrapper mb-3">
                <div class="link"
                     (click)="closeToggle()"

                     [routerLink]="!isPreproduction ? ['/dashboard/tickets'] : null"
                     routerLinkActive="router-link-active">
                    <i class="iconify" data-inline="false" data-icon="solar:ticket-linear"></i>
                    <a  [routerLink]="!isPreproduction ? ['/dashboard/tickets'] : null">Tickets</a>
                </div>
            </div>

            <div class="link-wrapper mb-3">
                <div class="link" routerLinkActive="submenu-item-active"
                     data-bs-toggle="collapse" data-bs-target="#request-dropdown" aria-expanded="true">
                    <i class="iconify" data-inline="false" data-icon="eos-icons:pull-request"></i>
                    <a href="javascript:void(0)">Requests</a>
                </div>
                <div class="collapse" id="request-dropdown" *ngIf="!isPreproduction">
                    <ul class="list-unstyled small nav-item ms-md-5 ms-3">
                        <li class="my-3">
                            <div class="submenu-item" routerLinkActive="submenu-item-active"
                                 (click)="closeToggle()"
                                 [routerLink]="['/dashboard/requests/coding-request']">
                                <a  [routerLink]="['/dashboard/requests/coding-request']">Coding & Implementation</a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="link-wrapper mb-3">
                <div class="link"
                     data-bs-toggle="collapse" data-bs-target="#master-dropdown" aria-expanded="true">
                    <i class="iconify" data-inline="false" data-icon="mage:file-records"></i>
                    <a href="javascript:void(0)">Master Records</a>
                </div>
                <div class="collapse" id="master-dropdown" *ngIf="!isPreproduction">
                    <ul class="list-unstyled small nav-item ms-md-5 ms-4">
                        <li class="my-3">
                            <div class="submenu-item" routerLinkActive="submenu-item-active"
                                 (click)="closeToggle()"
                                 [routerLink]="['/dashboard/master-records/platforms']">
                                <a href="javascript:void(0)">Platforms</a>
                            </div>
                        </li>
                        <li class="my-3">
                            <div
                                    class="submenu-item"
                                    (click)="closeToggle()"
                                    [routerLink]="['/dashboard/master-records/customers']">
                                <a href="javascript:void(0)">Customer Value/LAG/C5</a>
                            </div>
                        </li>
                        <li class="my-3">
                            <div class="submenu-item" routerLinkActive="submenu-item-active"
                                 (click)="closeToggle()"
                                 [routerLink]="['/dashboard/master-records/modules']">
                                <a href="javascript:void(0)">Modules</a>
                            </div>

                        </li>
                        <li class="my-3">
                            <div class="submenu-item" routerLinkActive="submenu-item-active"
                                 (click)="closeToggle()"
                                 [routerLink]="['/dashboard/master-records/production-units']">
                                <a href="javascript:void(0)">Production Units</a>
                            </div>

                        </li>

                        <li class="my-3">
                            <div class="submenu-item" routerLinkActive="submenu-item-active"
                                 (click)="closeToggle()"
                                 [routerLink]="['/dashboard/master-records/business-req']">
                                <a href="javascript:void(0)">Business Requirements</a>
                            </div>

                        </li>

                        <li class="my-3">
                            <div class="submenu-item" routerLinkActive="submenu-item-active"
                                 (click)="closeToggle()"
                                 [routerLink]="['/dashboard/master-records/lead-measure']">
                                <a href="javascript:void(0)">Lead Measures</a>
                            </div>

                        </li>

                        <li class="my-3">
                            <div class="submenu-item" routerLinkActive="submenu-item-active"
                                 (click)="closeToggle()"
                                 [routerLink]="['/dashboard/master-records/lag-measure']">
                                <a href="javascript:void(0)">Lag Measures</a>
                            </div>

                        </li>

                        <li class="my-3">
                            <div class="submenu-item" routerLinkActive="submenu-item-active"
                                 (click)="closeToggle()"
                                 [routerLink]="['/dashboard/execution/use-case']">
                                <a href="javascript:void(0)">Use Case</a>
                            </div>
                        </li>

                        <li class="my-3">
                            <div class="submenu-item" routerLinkActive="submenu-item-active"
                                 (click)="closeToggle()"
                                 [routerLink]="['/dashboard/master-records/work-item-status']">
                                <a href="javascript:void(0)">Work Item Status</a>
                            </div>
                        </li>

                    </ul>
                </div>
            </div>

            <div class="link-wrapper mb-3" *ngIf="canViewAuditTrail()">
                <div class="link"
                     (click)="closeToggle()"
                     [routerLink]="['/dashboard/audit-trail']"
                     routerLinkActive="router-link-active">
                    <i class="iconify" data-inline="false" data-icon="ion:footsteps-outline"></i>
                    <a href="javascript:void(0)">Audit Trail</a>
                </div>
            </div>

            <div  class="link-wrapper mb-3">
                <div class="link"
                     data-bs-toggle="collapse" data-bs-target="#process-dropdown" aria-expanded="true">
                    <i class="iconify" data-inline="false" data-icon="uil:process"></i>

                    <a href="javascript:void(0)">Process</a>
                </div>
                <div class="collapse" id="process-dropdown" *ngIf="!isPreproduction">
                    <ul class="list-unstyled small nav-item ms-md-5 ms-4">
                        <li class="my-3">
                            <div class="submenu-item" routerLinkActive="submenu-item-active"
                                 (click)="closeToggle()"
                                 [routerLink]="['/dashboard/process/compliance-list']">
                                <a href="javascript:void(0)">Compliance</a>
                            </div>
                        </li>
                        <li class="my-3">
                            <div class="submenu-item" routerLinkActive="submenu-item-active"
                                 (click)="closeToggle()"
                                 [routerLink]="['/dashboard/process/breach']">
                                <a href="javascript:void(0)">Breach</a>
                            </div>
                        </li>
                        <li class="my-3">
                            <div class="submenu-item" routerLinkActive="submenu-item-active"
                                 (click)="closeToggle()"
                                 [routerLink]="['/dashboard/process/financial-cost']">
                                <a href="javascript:void(0)">Financial Report</a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="link-wrapper mb-3">
                <div class="link"
                        data-bs-toggle="collapse" data-bs-target="#execution-dropdown" aria-expanded="true">
                    <i class="iconify" data-inline="false" data-icon="mage:file-records"></i>

                    <a href="javascript:void(0)">Execution</a>
                </div>
                <div class="collapse" id="execution-dropdown" *ngIf="!isPreproduction">
                    <ul class="list-unstyled small nav-item ms-md-5 ms-4">
                        <li class="my-3">
                            <div
                                    class="submenu-item"
                                    (click)="closeToggle()"
                                    [routerLink]="['/dashboard/master-records/wig']">
                                <a href="javascript:void(0)">Wildly Important Goals (D1)</a>
                            </div>
                        </li>
                        <li class="my-3">
                            <div class="submenu-item"
                                    (click)="closeToggle()"
                                    [routerLink]="['/dashboard/execution/commitment']">
                                <a href="javascript:void(0)">Lead Measure Commitments (D2)</a>
                            </div>
                        </li>
                        <li class="my-3">
                            <div class="submenu-item" routerLinkActive="submenu-item-active"
                                 (click)="closeToggle()"
                                 [routerLink]="['/dashboard/execution/wig/list']">
                                <a href="javascript:void(0)">Scoreboards (D3)</a>
                            </div>
                        </li>

                        <li class="my-3">
                            <div class="submenu-item" routerLinkActive="submenu-item-active"
                                 (click)="closeToggle()"
                                 [routerLink]="['/dashboard/execution/cadence']">
                                <a href="javascript:void(0)">Cadence (D4)</a>
                            </div>
                        </li>

                        <li class="my-3">
                            <div class="submenu-item" routerLinkActive="submenu-item-active"
                                 (click)="closeToggle()"
                                 [routerLink]="['/dashboard/delegation']">
                                <a href="javascript:void(0)">Delegation</a>
                            </div>
                        </li>

                        <li class="my-3">
                            <div class="submenu-item" routerLinkActive="submenu-item-active"
                                 (click)="closeToggle()"
                                 [routerLink]="['/dashboard/execution/player-stat']">
                                <a href="javascript:void(0)">Player Statistics</a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="link-wrapper mb-3" >
                <div class="link"
                    data-bs-toggle="collapse"
                    data-bs-target="#user-management"
                    aria-expanded="true">
                    <i class="iconify" data-inline="false" data-icon="solar:user-outline"></i>
                    <a href="javascript:void(0)">User Management</a>
                </div>
                <div class="collapse" id="user-management">
                    <ul class="list-unstyled small nav-item ms-md-5 ms-4">
                        <li class="my-3">
                            <div
                                class="submenu-item"
                                (click)="closeToggle()"
                                [routerLink]="['/dashboard/user-management/']">
                                <a href="javascript:void(0)">Users & Customers</a>
                            </div>
                        </li>
                        <li class="my-3">
                            <div
                                class="submenu-item"
                                (click)="closeToggle()"
                                [routerLink]="['/dashboard/user-management/roles-and-privileges']">
                                <a href="javascript:void(0)">Roles & Privileges</a>
                            </div>
                        </li>
                        <li class="my-3">
                            <div
                                class="submenu-item"
                                (click)="closeToggle()"
                                [routerLink]="['/dashboard/user-management/designations-and-departments']">
                                <a>Designations & Department</a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="link-wrapper mb-3">
                <div class="link"
                     (click)="closeToggle()"
                     [routerLink]="['/dashboard/customers']"
                     routerLinkActive="router-link-active">
                    <i class="iconify" data-inline="false" data-icon="streamline:information-desk-customer"></i>
                    <a href="javascript:void(0)">Customer Management</a>
                </div>
            </div>

            <div class="link-wrapper mb-3">
                <div class="link"
                     (click)="closeToggle()"

                     [routerLink]="['/dashboard/document-management']"
                     routerLinkActive="router-link-active">
                    <i class="iconify" data-inline="false" data-icon="fluent:document-20-regular"></i>
                    <a href="javascript:void(0)">Document Management</a>
                </div>
            </div>

            <div class="link-wrapper mb-3">
                <div class="link"
                        data-bs-toggle="collapse" data-bs-target="#analytics-dropdown" aria-expanded="true">
                    <i class="iconify" data-inline="false" data-icon="material-symbols:analytics-outline"></i>

                    <a href="javascript:void(0)">Analytics and Reporting</a>
                </div>
                <div class="collapse" id="analytics-dropdown">
                    <ul class="list-unstyled small nav-item ms-md-5 ms-4">
                        <li class="my-3">
                            <div
                                    class="submenu-item"
                                    (click)="closeToggle()"
                                    [routerLink]="['/dashboard/analytics-and-reporting/incident-report']">
                                <a href="javascript:void(0)">Incident Report</a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="link-wrapper mb-3">
                <div class="link"
                        data-bs-toggle="collapse" data-bs-target="#work-process-dropdown" aria-expanded="true">
                    <i class="iconify" data-inline="false" data-icon="uil:process"></i>

                    <a href="javascript:void(0)">Work Process</a>
                </div>
                <div class="collapse" id="work-process-dropdown">
                    <ul class="list-unstyled small nav-item ms-md-5 ms-4">
                        <li *ngIf="!isPreproduction" class="my-3">
                            <div
                                    class="submenu-item"
                                    (click)="closeToggle()"
                                    [routerLink]="['/dashboard/work-process/process-models']">
                                <a href="javascript:void(0)">Process Models</a>
                            </div>
                        </li>
                        <li *ngIf="!isProduction" class="my-3">
                            <div
                                    class="submenu-item"
                                    (click)="closeToggle()"
                                    [routerLink]="['/dashboard/work-process/define-processes']">
                                <a href="javascript:void(0)">Define Processes</a>
                            </div>
                        </li>
                        <li class="my-3">
                            <div
                                    class="submenu-item"
                                    (click)="closeToggle()"
                                    [routerLink]="['/dashboard/work-process/workflow-and-notifications']">
                                <a href="javascript:void(0)">Workflow & Notifications</a>
                            </div>
                        </li>

                        <li class="my-3">
                            <div *ngIf="!isProduction"
                                    class="submenu-item"
                                    (click)="closeToggle()"
                                    [routerLink]="['/dashboard/work-process/process-in-execution']">
                                <a href="javascript:void(0)">Process in Execution</a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="link-wrapper mb-3" *ngIf="canManageWorkProductDefinition()  || canManageWorkProduct()">
                <div class="link"
                     data-bs-toggle="collapse" data-bs-target="#work-product-dropdown" aria-expanded="true">
                    <i class="iconify" data-inline="false" data-icon="fluent-mdl2:product"></i>

                    <a href="javascript:void(0)">Work Product</a>
                </div>
                <div class="collapse" id="work-product-dropdown">
                    <ul class="list-unstyled small nav-item ms-md-5 ms-4">
                        <li class="my-3" *ngIf="canManageWorkProductDefinition()">
                            <div class="submenu-item"  routerLinkActive="submenu-item-active"
                                    (click)="closeToggle()"
                                    [routerLink]="['/dashboard/work-process/work-product']">
                                <a href="javascript:void(0)">Work Product Definition</a>
                            </div>
                        </li>
                        <li class="my-3" *ngIf="canManageWorkProduct()">
                            <div class="submenu-item" routerLinkActive="submenu-item-active"
                                 (click)="closeToggle()"
                                 [routerLink]="['/dashboard/work-process/search-work-products']">
                                <a href="javascript:void(0)">Work Product Creation</a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
