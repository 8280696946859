/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AttachmentDto } from './attachment-dto';


export interface DocumentDto { 
    id?: number;
    title: string;
    customerOrganisationId?: number;
    platformId?: number;
    processId?: number;
    departmentId?: number;
    documentCategoryId: number;
    documentReference?: string;
    documentDate: string;
    documentTags?: Array<string>;
    files?: Array<AttachmentDto>;
    scope?: DocumentDto.ScopeEnum;
}
export namespace DocumentDto {
    export type ScopeEnum = 'ORGANISATION' | 'PLATFORM' | 'CUSTOMER';
    export const ScopeEnum = {
        Organisation: 'ORGANISATION' as ScopeEnum,
        Platform: 'PLATFORM' as ScopeEnum,
        Customer: 'CUSTOMER' as ScopeEnum
    };
}


