/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AttachmentDto { 
    base64Data?: string;
    type?: AttachmentDto.TypeEnum;
    fileName?: string;
    plainData?: string;
    description?: string;
    bwBinaryDataId?: number;
}
export namespace AttachmentDto {
    export type TypeEnum = 'pdf' | 'image' | 'svg' | 'audio' | 'excel';
    export const TypeEnum = {
        Pdf: 'pdf' as TypeEnum,
        Image: 'image' as TypeEnum,
        Svg: 'svg' as TypeEnum,
        Audio: 'audio' as TypeEnum,
        Excel: 'excel' as TypeEnum
    };
}


