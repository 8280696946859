<div
        *ngIf="showAlertMessageTrigger"
        [ngClass]="{ anim: showAlertMessageTrigger }"
        [class.alert-success]="getMessageType() === AlertType.success"
        [class.alert-danger]="getMessageType() === AlertType.error"
        [class.alert-primary]="getMessageType() === AlertType.primary"
        [class.alert-info]="getMessageType() === AlertType.info"
        class="alert mb-4 fs-14 text-center"
>
    {{ getErrorMessage() }}
</div>

<div class="card mb-5 search">
    <div class="card-body">
        <form *ngIf="!showInlineKeyword" [formGroup]="form">
            <div class="mt-3 mb-5 p-3 mx-3 search bg-white row g-3 d-sm-flex align-items-center justify-content-between">
                <div class="col-md-9 mt-0">
                    <div class="row g-3 align-items-end">
                        <div *ngIf="showModuleControl || workProductionDefinitionId" class="col-md-6">
                            <div>
                                <label for="section" class="form-label"
                                       [ngClass]="{'required': showModuleControl && !workProductionDefinitionId}">Module</label>
                                <div>
                                    <select formControlName="moduleId" id="section" class="form-select">
                                        <option readonly [value]="''">Select Module</option>
                                        <option *ngFor="let p of modules$ | async"
                                                [value]="p.id">{{ p?.name | titlecase }}
                                        </option>
                                    </select>
                                </div>
                                <error-message
                                        [controlName]="'moduleId'"
                                        [singularError]="true"
                                        [form]="form"
                                ></error-message>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div>
                                <label for="keyword" class="form-label">Keyword</label>
                                <input type="text"
                                       placeholder="Search by keyword"
                                       id="keyword"
                                       class="form-control"
                                       formControlName="keyword"
                                       name="keyword"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="d-flex align-items-center float-end" [formGroup]="form">
                        <button [disabled]="searchManager.working"
                                class="btn btn-dark ms-3 px-3 d-flex align-items-center" (click)="submit()">
                            <i *ngIf="!searchManager.working">
                                <i class="iconify icon me-2"
                                   data-icon="iconamoon:search-thin"></i>
                            </i>
                            <app-loading *ngIf="this.searchManager.working"></app-loading>
                            Search
                        </button>
                    </div>
                </div>
            </div>
        </form>


        <ng-container *ngIf="!emptyListShowOnce">
            <form *ngIf="showInlineKeyword && !workProductionDefinitionId" [formGroup]="form" (submit)="submit()">
                <div class="row g-3 mb-2">
                    <div class="d-flex justify-content-end">
                        <div class="col-md-7">
                            <p *ngIf="showTitle" class="fs-17 fw-bold">Affected Production Unit</p>
                        </div>
                        <div class="col-md-5">
                            <div>
                                <label for="keyword" class="form-label">Keyword</label>
                                <input type="text"
                                       placeholder="Enter any Keyword"
                                       class="form-control"
                                       [formControlName]="'keyword'"
                                       name="keyword"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <page-size
                    *ngIf="searchManager.totalCount > 10"
                    [searchManager]="searchManager"
            ></page-size>
            <div [ngClass]="{'p-d3 px-4 bg-white background mdx-3': !workProductionDefinitionId}">

                <div class="d-flex justify-content-between flex-wrap p-1 bg-white ms-1 mt-2"
                     style="border-radius: 5px;"
                     *ngIf="showSelect "
                >
                    <div class="pos">
                        <mat-checkbox color="primary" (change)="$event ? toggleAllRows() : null"
                                      [checked]="selection.hasValue() && isAllSelected()"
                                      [indeterminate]="selection.hasValue() && !isAllSelected()">
                        </mat-checkbox>
                        <span class="fw-medium primary-color"> {{ this.selection.selected.length }} Selected</span>
                        <u class="fw-medium pointer primary-color" (click)="clearSelection()">Clear</u>
                    </div>

                    <button *ngIf="callToActionText" class="btn btn-dark px-4 btn-lg" (click)="mapTo()">
                        {{ callToActionText }}
                    </button>
                </div>
                <hr *ngIf="!workProductionDefinitionId">

                <!-- Original Production Unit Table -->
                <div class="card" style="border: none;">
                    <div class="card-bodyx">
                        <div [ngClass]="{'table-responsive': !workProductionDefinitionId}"
                             [ngStyle]="{'max-width: 100%; max-height: 280px; overflow: auto;': workProductionDefinitionId}">
                            <table [ngClass]="{'table table-striped': !workProductionDefinitionId, 'custom-table': workProductionDefinitionId}">
                                <thead>
                                <tr>
                                    <th *ngIf="showSelect" scope="col"></th>
                                    <th scope="col">SN</th>
                                    <th *ngIf="!showModuleControl || workProductionDefinitionId" scope="col">Module ID
                                    </th>
                                    <th scope="col">Production Unit Type</th>
                                    <th scope="col">Production Unit Name</th>
                                    <th *ngIf="showReleaseDate" scope="col">Release Date</th>
                                </tr>
                                </thead>
                                <ng-container *ngIf="!searchManager._working; else searching">
                                    <ng-container *ngIf="searchManager.list.length > 0; else noData">
                                        <tbody>
                                        <tr
                                                class="justify-content-center"
                                                *ngFor="let unit of searchManager.list; let i = index">
                                            <td *ngIf="showSelect">
                                                <mat-checkbox color="primary" (click)="$event.stopPropagation()"
                                                              (change)="$event ? selection.toggle(unit.id) : null"
                                                              [checked]="selection.isSelected(unit.id)">
                                                </mat-checkbox>
                                            </td>
                                            <td>{{ i + searchManager.offset + 1 }}</td>
                                            <td *ngIf="!showModuleControl || workProductionDefinitionId">{{ unit?.softwareModule?.name | uppercase }}</td>
                                            <td>{{ unit?.softwareUnitType?.name }}</td>
                                            <td>
                                                {{ helperService.cutText(unit?.name || '-', 40) }}
                                                <span *ngIf="unit?.name?.length >= 41" [placement]="'right'"
                                                      [tooltip]="unit?.name">
                                                <span class="iconify text-black pointer"
                                                      data-icon="material-symbols:info"> </span>
                                            </span>
                                            </td>
                                            <td *ngIf="showReleaseDate">{{ unit?.currentPlannedCICompletionDate | date }}</td>
                                        </tr>
                                        </tbody>
                                    </ng-container>
                                </ng-container>
                                <ng-template #searching>
                                    <tr>
                                        <td colspan="100%">
                                            <div class="d-flex justify-content-center">
                                                <div class="spinner-border" role="status">
                                                    <span class="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-template>

                                <ng-template #noData>
                                    <tr>
                                        <td class="text-center" colspan="9">NO DATA</td>
                                    </tr>
                                </ng-template>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div
                    *ngIf="searchManager.totalCount > 10"
                    class="row py-2"
                    [ngClass]="{ 'disabled-table': searchManager.working }"
            >
                <div class="col-12 d-flex justify-content-end">
                    <pagination
                            [(ngModel)]="searchManager.page"
                            [boundaryLinks]="true"
                            [itemsPerPage]="searchManager.itemsPerPage"
                            [maxSize]="maxPageSize"
                            [rotate]="true"
                            [totalItems]="searchManager.totalCount"
                            firstText="&laquo;"
                            lastText="&raquo;"
                            nextText="&rsaquo;"
                            previousText="&lsaquo;"
                    ></pagination>
                </div>
            </div>
        </ng-container>
    </div>
</div>
