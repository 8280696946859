/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface LinkedDocumentPojo { 
    id?: number;
    name?: string;
    linkTypeConstant?: LinkedDocumentPojo.LinkTypeConstantEnum;
    referenceId?: number;
    targetId?: number;
}
export namespace LinkedDocumentPojo {
    export type LinkTypeConstantEnum = 'RESPONSE' | 'RELATED';
    export const LinkTypeConstantEnum = {
        Response: 'RESPONSE' as LinkTypeConstantEnum,
        Related: 'RELATED' as LinkTypeConstantEnum
    };
}


