/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NameIdPojo } from './name-id-pojo';
import { AttachmentPojo } from './attachment-pojo';
import { LinkedDocumentPojo } from './linked-document-pojo';


export interface DocumentPojo { 
    id?: number;
    createdAt?: string;
    portalAccountId?: number;
    createdBy?: NameIdPojo;
    documentCategory?: NameIdPojo;
    customerOrganization?: NameIdPojo;
    department?: NameIdPojo;
    platform?: NameIdPojo;
    process?: NameIdPojo;
    documentTitle?: string;
    documentReference?: string;
    organizationScope?: boolean;
    identifier?: string;
    versionNumber?: number;
    documentDate?: string;
    attachmentPojos?: Array<AttachmentPojo>;
    tags?: Array<NameIdPojo>;
    scope?: DocumentPojo.ScopeEnum;
    linkedToDocuments?: Array<LinkedDocumentPojo>;
}
export namespace DocumentPojo {
    export type ScopeEnum = 'ORGANISATION' | 'PLATFORM' | 'CUSTOMER';
    export const ScopeEnum = {
        Organisation: 'ORGANISATION' as ScopeEnum,
        Platform: 'PLATFORM' as ScopeEnum,
        Customer: 'CUSTOMER' as ScopeEnum
    };
}


