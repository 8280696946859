/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { BlockerDto } from '../model/models';
import { BlockerPojo } from '../model/models';
import { BlockerStatusUpdateDto } from '../model/models';
import { QueryResultsBlockerPojo } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface CreateBlockerRequestParams {
    blockerDto: BlockerDto;
}

export interface DeleteBlockerRequestParams {
    id: number;
}

export interface SearchBlockerRequestParams {
    identifier?: string;
    blockerCategory?: string;
    resolvedAt?: string;
    resolvedBy?: string;
    description?: string;
    portalAccountId?: number;
    portalAccount?: string;
    createdAt?: string;
    resolutionStatus?: 'PENDING' | 'IN_PROGRESS' | 'RESOLVED' | 'CANCELLED';
    createdBy?: string;
    possibleInfluencer?: string;
    id?: number;
    affectedBlockerDomainEntity?: string;
    externalSystemIssueUrl?: string;
    status?: 'ACTIVE' | 'INACTIVE' | 'DELETED';
    templateType?: 'NORMAL' | 'DROPDOWN_DATA' | 'LEAN';
    createdByName?: string;
    softwareModuleId?: number;
    customerValueId?: number;
    delegationLogId?: number;
    offset?: number;
    affectedDomainEntityType?: 'MODULE' | 'CUSTOMER_VALUE' | 'PRODUCTION_UNIT' | 'DELEGATION' | 'TICKET' | 'ACTIVITY';
    affectedDomainEntityId?: number;
    createdAfter?: string;
    priorityItems?: Array<object>;
    productionUnitId?: number;
    wigId?: number;
    blockerCategoryId?: number;
    limit?: number;
    createdBefore?: string;
    keyword?: string;
    leadMeasureId?: number;
    orderColumn?: string;
    order?: 'ASC' | 'DESC';
}

export interface UpdateBlockerRequestParams {
    blockerDto: BlockerDto;
}

export interface UpdateBlockerStatusRequestParams {
    blockerStatusUpdateDto: BlockerStatusUpdateDto;
}


@Injectable({
  providedIn: 'root'
})
export class BlockerControllerService {

    protected basePath = 'http://support.byteproducts.com/api';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createBlocker(requestParameters: CreateBlockerRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<BlockerPojo>;
    public createBlocker(requestParameters: CreateBlockerRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<BlockerPojo>>;
    public createBlocker(requestParameters: CreateBlockerRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<BlockerPojo>>;
    public createBlocker(requestParameters: CreateBlockerRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const blockerDto = requestParameters.blockerDto;
        if (blockerDto === null || blockerDto === undefined) {
            throw new Error('Required parameter blockerDto was null or undefined when calling createBlocker.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<BlockerPojo>(`${this.configuration.basePath}/blockers`,
            blockerDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteBlocker(requestParameters: DeleteBlockerRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<object>;
    public deleteBlocker(requestParameters: DeleteBlockerRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<object>>;
    public deleteBlocker(requestParameters: DeleteBlockerRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<object>>;
    public deleteBlocker(requestParameters: DeleteBlockerRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteBlocker.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<object>(`${this.configuration.basePath}/blockers/${encodeURIComponent(String(id))}/delete`,
            null,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Blocker
     * Filter Blocker
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchBlocker(requestParameters: SearchBlockerRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<QueryResultsBlockerPojo>;
    public searchBlocker(requestParameters: SearchBlockerRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<QueryResultsBlockerPojo>>;
    public searchBlocker(requestParameters: SearchBlockerRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<QueryResultsBlockerPojo>>;
    public searchBlocker(requestParameters: SearchBlockerRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const identifier = requestParameters.identifier;
        const blockerCategory = requestParameters.blockerCategory;
        const resolvedAt = requestParameters.resolvedAt;
        const resolvedBy = requestParameters.resolvedBy;
        const description = requestParameters.description;
        const portalAccountId = requestParameters.portalAccountId;
        const portalAccount = requestParameters.portalAccount;
        const createdAt = requestParameters.createdAt;
        const resolutionStatus = requestParameters.resolutionStatus;
        const createdBy = requestParameters.createdBy;
        const possibleInfluencer = requestParameters.possibleInfluencer;
        const id = requestParameters.id;
        const affectedBlockerDomainEntity = requestParameters.affectedBlockerDomainEntity;
        const externalSystemIssueUrl = requestParameters.externalSystemIssueUrl;
        const status = requestParameters.status;
        const templateType = requestParameters.templateType;
        const createdByName = requestParameters.createdByName;
        const softwareModuleId = requestParameters.softwareModuleId;
        const customerValueId = requestParameters.customerValueId;
        const delegationLogId = requestParameters.delegationLogId;
        const offset = requestParameters.offset;
        const affectedDomainEntityType = requestParameters.affectedDomainEntityType;
        const affectedDomainEntityId = requestParameters.affectedDomainEntityId;
        const createdAfter = requestParameters.createdAfter;
        const priorityItems = requestParameters.priorityItems;
        const productionUnitId = requestParameters.productionUnitId;
        const wigId = requestParameters.wigId;
        const blockerCategoryId = requestParameters.blockerCategoryId;
        const limit = requestParameters.limit;
        const createdBefore = requestParameters.createdBefore;
        const keyword = requestParameters.keyword;
        const leadMeasureId = requestParameters.leadMeasureId;
        const orderColumn = requestParameters.orderColumn;
        const order = requestParameters.order;

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (identifier !== undefined && identifier !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>identifier, 'identifier');
        }
        if (blockerCategory !== undefined && blockerCategory !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>blockerCategory, 'blockerCategory');
        }
        if (resolvedAt !== undefined && resolvedAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>resolvedAt, 'resolvedAt');
        }
        if (resolvedBy !== undefined && resolvedBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>resolvedBy, 'resolvedBy');
        }
        if (description !== undefined && description !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>description, 'description');
        }
        if (portalAccountId !== undefined && portalAccountId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>portalAccountId, 'portalAccountId');
        }
        if (portalAccount !== undefined && portalAccount !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>portalAccount, 'portalAccount');
        }
        if (createdAt !== undefined && createdAt !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAt, 'createdAt');
        }
        if (resolutionStatus !== undefined && resolutionStatus !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>resolutionStatus, 'resolutionStatus');
        }
        if (createdBy !== undefined && createdBy !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdBy, 'createdBy');
        }
        if (possibleInfluencer !== undefined && possibleInfluencer !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>possibleInfluencer, 'possibleInfluencer');
        }
        if (id !== undefined && id !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>id, 'id');
        }
        if (affectedBlockerDomainEntity !== undefined && affectedBlockerDomainEntity !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>affectedBlockerDomainEntity, 'affectedBlockerDomainEntity');
        }
        if (externalSystemIssueUrl !== undefined && externalSystemIssueUrl !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>externalSystemIssueUrl, 'externalSystemIssueUrl');
        }
        if (status !== undefined && status !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>status, 'status');
        }
        if (templateType !== undefined && templateType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>templateType, 'templateType');
        }
        if (createdByName !== undefined && createdByName !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdByName, 'createdByName');
        }
        if (softwareModuleId !== undefined && softwareModuleId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>softwareModuleId, 'softwareModuleId');
        }
        if (customerValueId !== undefined && customerValueId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>customerValueId, 'customerValueId');
        }
        if (delegationLogId !== undefined && delegationLogId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>delegationLogId, 'delegationLogId');
        }
        if (offset !== undefined && offset !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>offset, 'offset');
        }
        if (affectedDomainEntityType !== undefined && affectedDomainEntityType !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>affectedDomainEntityType, 'affectedDomainEntityType');
        }
        if (affectedDomainEntityId !== undefined && affectedDomainEntityId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>affectedDomainEntityId, 'affectedDomainEntityId');
        }
        if (createdAfter !== undefined && createdAfter !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdAfter, 'createdAfter');
        }
        if (priorityItems) {
            priorityItems.forEach((element) => {
                queryParameters = this.addToHttpParams(queryParameters,
                  <any>element, 'priorityItems');
            })
        }
        if (productionUnitId !== undefined && productionUnitId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>productionUnitId, 'productionUnitId');
        }
        if (wigId !== undefined && wigId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>wigId, 'wigId');
        }
        if (blockerCategoryId !== undefined && blockerCategoryId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>blockerCategoryId, 'blockerCategoryId');
        }
        if (limit !== undefined && limit !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>limit, 'limit');
        }
        if (createdBefore !== undefined && createdBefore !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>createdBefore, 'createdBefore');
        }
        if (keyword !== undefined && keyword !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>keyword, 'keyword');
        }
        if (leadMeasureId !== undefined && leadMeasureId !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>leadMeasureId, 'leadMeasureId');
        }
        if (orderColumn !== undefined && orderColumn !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>orderColumn, 'orderColumn');
        }
        if (order !== undefined && order !== null) {
          queryParameters = this.addToHttpParams(queryParameters,
            <any>order, 'order');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.get<QueryResultsBlockerPojo>(`${this.configuration.basePath}/blockers`,
            {
                params: queryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBlocker(requestParameters: UpdateBlockerRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<BlockerPojo>;
    public updateBlocker(requestParameters: UpdateBlockerRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<BlockerPojo>>;
    public updateBlocker(requestParameters: UpdateBlockerRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<BlockerPojo>>;
    public updateBlocker(requestParameters: UpdateBlockerRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const blockerDto = requestParameters.blockerDto;
        if (blockerDto === null || blockerDto === undefined) {
            throw new Error('Required parameter blockerDto was null or undefined when calling updateBlocker.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.put<BlockerPojo>(`${this.configuration.basePath}/blockers/edit`,
            blockerDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBlockerStatus(requestParameters: UpdateBlockerStatusRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<object>;
    public updateBlockerStatus(requestParameters: UpdateBlockerStatusRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpResponse<object>>;
    public updateBlockerStatus(requestParameters: UpdateBlockerStatusRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*'}): Observable<HttpEvent<object>>;
    public updateBlockerStatus(requestParameters: UpdateBlockerStatusRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: '*/*'}): Observable<any> {
        const blockerStatusUpdateDto = requestParameters.blockerStatusUpdateDto;
        if (blockerStatusUpdateDto === null || blockerStatusUpdateDto === undefined) {
            throw new Error('Required parameter blockerStatusUpdateDto was null or undefined when calling updateBlockerStatus.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (ApiKey) required
        credential = this.configuration.lookupCredential('ApiKey');
        if (credential) {
            headers = headers.set('Authorization', credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                '*/*'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType_ = 'text';
        }

        return this.httpClient.post<object>(`${this.configuration.basePath}/blockers/update-status`,
            blockerStatusUpdateDto,
            {
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
