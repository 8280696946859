/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface BlockerDto { 
    id?: number;
    description: string;
    blockerCategoryId: number;
    resolutionStatus?: BlockerDto.ResolutionStatusEnum;
    externalSystemIssueUrl?: string;
    affectedDomainEntityType: BlockerDto.AffectedDomainEntityTypeEnum;
    affectedDomainEntityId: number;
    influencerPortalUserId: number;
}
export namespace BlockerDto {
    export type ResolutionStatusEnum = 'PENDING' | 'IN_PROGRESS' | 'RESOLVED' | 'CANCELLED';
    export const ResolutionStatusEnum = {
        Pending: 'PENDING' as ResolutionStatusEnum,
        InProgress: 'IN_PROGRESS' as ResolutionStatusEnum,
        Resolved: 'RESOLVED' as ResolutionStatusEnum,
        Cancelled: 'CANCELLED' as ResolutionStatusEnum
    };
    export type AffectedDomainEntityTypeEnum = 'MODULE' | 'CUSTOMER_VALUE' | 'PRODUCTION_UNIT' | 'DELEGATION' | 'TICKET' | 'ACTIVITY';
    export const AffectedDomainEntityTypeEnum = {
        Module: 'MODULE' as AffectedDomainEntityTypeEnum,
        CustomerValue: 'CUSTOMER_VALUE' as AffectedDomainEntityTypeEnum,
        ProductionUnit: 'PRODUCTION_UNIT' as AffectedDomainEntityTypeEnum,
        Delegation: 'DELEGATION' as AffectedDomainEntityTypeEnum,
        Ticket: 'TICKET' as AffectedDomainEntityTypeEnum,
        Activity: 'ACTIVITY' as AffectedDomainEntityTypeEnum
    };
}


