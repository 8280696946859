import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {IDropdownSettings} from 'ng-multiselect-dropdown';
import {
    ForecastProductionUnitSearchComponent
} from "../../../master-records/production-units/components/forecast-production-unit-search/forecast-production-unit-search.component";
import {
    CreateComplianceEntryLogDto,
    MRPPojo,
    MRPRequestDto,
    MRPUatPojo,
    NameCodeIdPojo, NameIdPojo,
    ProductionProcessComplianceControllerService,
    QueryResultsSoftwareModulePojo,
    QueryResultsUseCasePojo,
    SoftwareProductionUnitPojo,
    SprintControllerService, SprintPlanPojo,
    SprintPojo,
    UseCaseControllerService,
    UseCasePojo,
    WigControllerService,
    WigPojo
} from "../../../../../../../sdk/customer-fulfillment-api-sdk";
import {FormBuilder, Validators} from "@angular/forms";
import {HelperService} from "../../../../../services/helper.service";
import {Observable, of, switchMap} from "rxjs";
import {catchError, map, tap} from "rxjs/operators";
import {
    MrpUatEditScheduleSearchComponent,
    UATSchedule
} from "../mrp-uat-edit-schedule-search/mrp-uat-edit-schedule-search.component";
import {environment} from "../../../../../../environments/environment";
import {PlatformSelectorService} from "../../../../../services/platform-selector.service";
import {AlertType} from "../../../../extranet/report-issue/report-issue.component";
import {FormHelper} from "../../../../../models/etc/form-helper";
import {BsDatepickerConfig} from "ngx-bootstrap/datepicker";
import {ViewPuDialogComponent} from "../view-pu-dialog/view-pu-dialog.component";
import moment from "moment";

@Component({
    selector: 'app-module-release-planning',
    templateUrl: './module-release-planning.component.html',
    styleUrls: ['./module-release-planning.component.scss']
})
export class ModuleReleasePlanningComponent implements OnInit, AfterViewInit {

    @Input() sprintPlan: SprintPlanPojo;

    @Input() mrpComplianceId: number;

    form = this.fb.group({
        sprintName: ['',
            Validators.compose([
                Validators.required,
                Validators.pattern(FormHelper.ALPHANUMERIC_WITH_HYPHEN_HASH_DOT_SLASH_UNDERSCORE_PIPE_SPACE_NOT_CONSECUTIVE_COMMA_AMPERSAND_BRACES_COLON),
                Validators.minLength(FormHelper.DESCRIPTION_FIELD_MIN_LENGTH),
                Validators.maxLength(FormHelper.DESCRIPTION_FIELD_MAX_LENGTH)
            ])],
        sprintId: [''],
        sprintType: [true],
        wigId: ['',
        ],
        startDate: [undefined as any,
            Validators.compose([
                Validators.required,
            ])],
        endDate: [undefined as any,
            Validators.compose([
                Validators.required,
            ])],
        uatId: [undefined as number],
        uatName: ['',
            Validators.compose([
                Validators.required,
                Validators.pattern(FormHelper.ALPHANUMERIC_WITH_HYPHEN_HASH_DOT_SLASH_UNDERSCORE_PIPE_SPACE_NOT_CONSECUTIVE_COMMA_AMPERSAND_BRACES_COLON),
                Validators.minLength(FormHelper.DESCRIPTION_FIELD_MIN_LENGTH),
                Validators.maxLength(FormHelper.DESCRIPTION_FIELD_MAX_LENGTH)
            ])],
        uatScheduledDate: [undefined as any,
            Validators.compose([
                Validators.required,
            ])],
        useCase: [[] as Array<NameCodeIdPojo>,
            Validators.compose([
                Validators.required,
            ])],
        platformId: [undefined as number,
            Validators.compose([
                Validators.required,
            ])],
        stageCode: [environment.mrpProcessCode,
            Validators.compose([
                Validators.required,
            ])],
    })

    useCases$: Observable<Array<UseCasePojo>>

    sprints$: Observable<Array<SprintPojo>>

    sprints: Array<SprintPojo>

    stillLoading = false;

    @Input() saveButtonText: string;
    @Output() saveEvent: EventEmitter<NameIdPojo> = new EventEmitter<NameIdPojo>();
    @Input() goBackAfterSave = true;

    @ViewChild("forecastPUSearchComponent")
    forecastPUSearchComponent: ForecastProductionUnitSearchComponent;

    @ViewChild("uatEditComponent")
    uatEditComponent: MrpUatEditScheduleSearchComponent;

    @Output()
    showAlertMessage: EventEmitter<{ msg: string; type: AlertType }> = new EventEmitter<any>();

    initialFormData: any;

    wigs$: Observable<Array<WigPojo>>;

    minDate: any;
    maxDate: any;

    dropdownSettings: IDropdownSettings = {
        singleSelection: false,
        selectAllText: 'Select All',
        unSelectAllText: 'Unselect All',
        idField: 'id',
        textField: 'name',
        allowSearchFilter: true,
        noDataAvailablePlaceholderText: 'No Data'
    };

    readonly _datePlaceHolder: string = 'DD-MM-YYYY h:mm:ss a';
    _datePickerConfig: Partial<BsDatepickerConfig> = {
        dateInputFormat: this._datePlaceHolder,
        containerClass: 'theme-dark-blue',
        adaptivePosition: true,
        showWeekNumbers: false,
        displayOneMonthRange: true,
        returnFocusToInput: true,
        withTimepicker: true,
        keepDatepickerOpened: true,
        daysDisabled: [0, 6]
    };


    constructor(private fb: FormBuilder,
                protected helperService: HelperService,
                private useCaseService: UseCaseControllerService,
                private processComplianceService: ProductionProcessComplianceControllerService,
                private platformSelectorService: PlatformSelectorService,
                private wigService: WigControllerService,
                private sprintService: SprintControllerService) {
    }

    ngOnInit(): void {
        this.fetchUseCases();
        this.fetchWig();
        if (this.sprintPlan || this.mrpComplianceId) this.initUpdate();
    }

    ngAfterViewInit(): void {
        this.updateForecastPUFormValidators();
        this.initListeners();
        this.saveInitFormData();
    }

    saveInitFormData(): void {
        this.initialFormData = this.form.getRawValue();
    }

    updateForecastPUFormValidators(): void {
        this.forecastPUSearchComponent.form.controls.searchForm.controls.userAcceptanceTestId.removeValidators(Validators.required);
        this.forecastPUSearchComponent.form.controls.searchForm.controls.userAcceptanceTestId.updateValueAndValidity();
        this.forecastPUSearchComponent.form.controls.searchForm.controls.useCaseIds.addValidators(Validators.required);
        this.forecastPUSearchComponent.form.controls.searchForm.controls.useCaseIds.updateValueAndValidity();
    }

    fetchWig() {

        this.wigs$ =
            this.platformSelectorService.currentPlatform
                .pipe(
                    switchMap((selected) => {
                        if (selected && selected !== 0) {
                            return this.wigService.searchWigs({
                                limit: 1000,
                                platformId: Number(selected),
                            })
                                .pipe(
                                    catchError(error => {
                                        console.error('Error occurred:', error);
                                        return of({results: []});
                                    })
                                );
                        } else {
                            return of({results: []})
                        }
                    }),
                    map((x: QueryResultsSoftwareModulePojo) => x.results),
                );
    }

    initListeners(): void {

        this.platformSelectorService.currentPlatform.subscribe(value => {
            if (value) {
                this.form.patchValue({
                    platformId: value
                })

                this.fetchSprints();
            }
        })

        this.form.controls.useCase.valueChanges
            .subscribe(value => {
                const useCaseIds = value.map(x => x.id);
                this.forecastPUSearchComponent.updateUseCaseIds(useCaseIds);
                this.forecastPUSearchComponent.updateProductionUnitsForSelectedUseCases(useCaseIds)

                if (useCaseIds?.length == 0) {
                    this.forecastPUSearchComponent.searchManager.queryResult = {results: []};
                } else {
                    this.forecastPUSearchComponent.submit();
                }
            })

        this.form.controls.startDate.valueChanges.subscribe((v: string) => {
            this.minDate = v;

            if (this.form.controls.endDate?.value) {

                if (this.form.controls.endDate.value < v) {
                    this.form.controls.endDate.setValue(null, {emitEvent: false});
                }
            }
        });

        this.form.controls.endDate.valueChanges.subscribe((v: string) => {
            this.maxDate = v;

            if (this.form.controls?.uatScheduledDate?.value) {
                if (this.form.controls.uatScheduledDate.value > this.maxDate) {
                    this.form.controls.endDate.setValue(null);
                }
            }
        });

        this.form.controls.sprintId.valueChanges.subscribe((v: string) => {

            if (v && !this.isNewSprint) {
                const sprintId = Number(v);
                //fetch mrp details
                this.fetchMRPDetails(sprintId)
            }

        })

        this.form.controls.sprintType.valueChanges.subscribe((v: boolean) => {
            if (v === true) {
                this.form.controls.sprintName.addValidators([Validators.required]);
                this.form.controls.sprintName.updateValueAndValidity()
                this.form.controls.sprintId.removeValidators(Validators.required);
                this.form.controls.sprintId.updateValueAndValidity();

                if (this.form.controls.sprintId.value != '') {
                    this.form.controls.wigId.setValue('');
                    this.form.controls.startDate.setValue('', {emitEvent: false});
                    this.form.controls.endDate.setValue('', {emitEvent: false});
                    this.resetUATList();
                }
            } else {
                this.form.controls.sprintName.removeValidators(Validators.required);
                this.form.controls.sprintName.updateValueAndValidity()
                this.form.controls.sprintId.addValidators(Validators.required);
                this.form.controls.sprintId.updateValueAndValidity()
            }
        })
    }

    hasNoDate(): boolean {
        return this.forecastPUSearchComponent
            ?.forecastFormArrayValue
            ?.some(value => {
                return (value && (value.currentPlannedCICompletionDate == undefined))
            });
    }

    addUAT(): void {

        if (this.isNewSprint) {

            if (this.form.invalid) {
                console.log("invalid ", this.helperService.getInvalidFormFields(this.form));
                return;
            }
        } else {
            if (this.form.controls.sprintId.invalid ||
                this.form.controls.startDate.invalid ||
                this.form.controls.endDate.invalid ||
                this.form.controls.wigId.invalid ||
                this.form.controls.uatName.invalid ||
                this.form.controls.uatScheduledDate.invalid ||
                this.form.controls.useCase.invalid) {

                this.helperService.getInvalidFormFields(this.form);
                return;
            }
        }

        if (this.hasNoDate()) {
            this.showAlertMessage.emit({
                msg: 'You must select a release date for all production units before adding a UAT',
                type: AlertType.error
            });
            return;
        }

        const form = this.form.getRawValue();

        //check duplicate uat name
        const duplicateUAT = this.uatEditComponent.uatSchedules.find(x => x.uatName?.toLowerCase() == form.uatName?.toLowerCase());

        if (duplicateUAT) {
            this.showAlertMessage.emit({
                msg: `UAT with name ${duplicateUAT?.uatName} already exists`,
                type: AlertType.error
            });
            return;
        }

        const productionUnitReleaseForecasts = this.forecastPUSearchComponent.forecastFormArrayValue;

        const schedule: UATSchedule = {
            puSet: productionUnitReleaseForecasts.map(forecast => {
                return {
                    name: '',
                    id: forecast?.softwareProductionUnitId,
                    code: forecast?.currentPlannedCICompletionDate
                }
            }),
            uatId: form.uatId,
            uatName: form.uatName,
            uatScheduledDate: moment(form.uatScheduledDate, this._datePlaceHolder).format(environment.dateTimeFormat),
            useCaseSet: form.useCase,
            sprintName: this.getSprintName(),
            sprintStartDate: moment(form.startDate, this._datePlaceHolder).format(environment.dateTimeFormat),
            sprintEndDate: moment(form.endDate, this._datePlaceHolder).format(environment.dateTimeFormat),
            wigId: form.wigId,
        }

        this.uatEditComponent.uatSchedules.push(schedule);
        this.uatEditComponent.getData(1);
        this.resetUATForm();
        this.forecastPUSearchComponent.clearFormControlMap();
        this.forecastPUSearchComponent.clearForecastControl();

    }

    getSprintName(): string {

        if (this.isNewSprint) {
            return this.form.controls.sprintName.value

        } else {
            const sprintId = Number(this.form.controls.sprintId.value);
            return this.sprints.find(x => x.id == sprintId)?.name;
        }
    }

    get isNewSprint(): boolean {
        return this.form.controls.sprintType.value == true;
    }

    addUATFromExisting(uat: MRPUatPojo, mrpPojo: MRPPojo): void {

        const schedule: UATSchedule = {
            puSet: uat.softwareProductionUnits.map(forecast => {
                return {
                    name: '',
                    id: forecast?.id,
                    code: forecast?.date
                }
            }),
            uatName: uat.uatName,
            uatScheduledDate: uat.uatScheduledTime,
            uatId: uat?.uatId,
            useCaseSet: uat.useCases,
            sprintName: mrpPojo.sprintName,
            sprintStartDate: mrpPojo.sprintStartTime,
            sprintEndDate: mrpPojo.sprintEndTime,
            wigId: mrpPojo.wig?.id ? mrpPojo.wig?.id?.toString() : ''
        }

        this.uatEditComponent.uatSchedules.push(schedule);
        this.uatEditComponent.getData(1);
        this.resetUATForm();
        this.forecastPUSearchComponent.clearFormControlMap();
        this.forecastPUSearchComponent.clearForecastControl();

    }

    resetUATForm(): void {
        this.form.controls.uatName.reset();
        this.form.controls.uatScheduledDate.reset();
        this.form.controls.useCase.reset([], {emitEvent: true});
    }

    resetForm(): void {
        this.form.reset(this.initialFormData);
    }

    edit(index: number): void {
        const schedule = this.uatEditComponent.uatSchedules[index];
        this.editUATSchedule(schedule);
        this.remove(index);
    }

    remove(index: number): void {
        this.uatEditComponent.deleteKeyResult(index);
    }

    editUATSchedule(uatSchedule: UATSchedule): void {

        console.log(uatSchedule);

        this.form.patchValue({
            uatId: uatSchedule?.uatId,
            uatName: uatSchedule.uatName,
            uatScheduledDate: moment(uatSchedule.uatScheduledDate).toDate(),
            sprintName: uatSchedule.sprintName,
            useCase: uatSchedule.useCaseSet,
            wigId: uatSchedule.wigId
        });

        //clear form before editing
        this.forecastPUSearchComponent.clearFormControlMap();
        this.forecastPUSearchComponent.clearForecastControl();

        //send puSet forecast dates to selector
        this.forecastPUSearchComponent.setForecastDates(uatSchedule.puSet);
    }

    fetchUseCases(): void {
        this.useCases$ = this.useCaseService.searchUseCase({limit: 1000})
            .pipe(
                catchError(error => {
                    console.error('Error occurred:', error);
                    return of({results: []});
                }),
                map((result: QueryResultsUseCasePojo) => result.results)
            )
    }

    fetchSprints(): void {
        this.sprints$ = this.sprintService.getAllSprints({platformId: this.form.controls.platformId.value})
            .pipe(
                tap(result => this.sprints = result),
                catchError(error => {
                    console.error('Error occurred:', error);
                    return of([]);
                })
            )
    }

    fetchMRPDetails(sprintId?: number): void {
        this.disableFieldsForUserExperience();

        const apiCall = sprintId ? this.getLatestMrp(sprintId) : this.getMrp();


        apiCall.subscribe({
                next: (response) => {
                    this.resetUATList();
                    this.updateFormFieldsForExistingSprint(response);
                },
                complete: () => {
                    this.enableFieldsForUserExperience();
                },
                error: (error: unknown) => {
                    this.enableFieldsForUserExperience();
                    this.showAlertMessage.emit({msg: this.helperService.getError(error), type: AlertType.error});
                }
            })
    }

    getLatestMrp(sprintId: number): Observable<MRPPojo> {
        return this.processComplianceService.getLatestApprovedMRP({ sprintId });
    }

    getMrp(): Observable<MRPPojo> {
        return this.processComplianceService.getMRP({ complianceId: this.sprintPlan.complianceId || this.mrpComplianceId });
    }

    disableFieldsForUserExperience(): void {
        this.form.controls.wigId.disable({emitEvent: false});
        this.form.controls.startDate.disable({emitEvent: false});
        this.form.controls.endDate.disable({emitEvent: false});
        this.form.controls.uatName.disable({emitEvent: false});
        this.form.controls.uatScheduledDate.disable({emitEvent: false});
        this.form.controls.useCase.disable({emitEvent: false});
    }

    enableFieldsForUserExperience(): void {
        this.form.controls.wigId.enable({emitEvent: false});
        this.form.controls.startDate.enable({emitEvent: false});
        this.form.controls.endDate.enable({emitEvent: false});
        this.form.controls.uatName.enable({emitEvent: false});
        this.form.controls.uatScheduledDate.enable({emitEvent: false});
        this.form.controls.useCase.enable({emitEvent: false});
    }

    updateFormFieldsForExistingSprint(mrpPojo: MRPPojo): void {
        this.form.patchValue({
            wigId: mrpPojo.wig?.id ? mrpPojo.wig?.id?.toString() : '',
        }, {emitEvent: false});

        this.form.controls.sprintName.setValue(mrpPojo?.sprintName);
        this.form.controls.startDate.setValue(new Date(mrpPojo?.sprintStartTime));
        this.form.controls.endDate.setValue(new Date(mrpPojo?.sprintEndTime));

        mrpPojo?.uats?.forEach(uat => {
            this.addUATFromExisting(uat, mrpPojo);
        });
    }

    resetUATList(): void {
        this.uatEditComponent.uatSchedules = [];
        this.uatEditComponent.getData(1);
    }

    submit(): void {

        if (this.form.controls.startDate.invalid ||
            this.form.controls.endDate.invalid ||
            this.form.controls.wigId.invalid) {

            this.form.controls.startDate.markAsTouched();
            this.form.controls.endDate.markAsTouched();
            this.form.controls.wigId.markAsTouched();
            return;
        }

        if (this.isNewSprint) {

            if (this.form.controls.sprintName.invalid) {
                this.form.controls.sprintName.markAsTouched();
                return;
            }

        } else {

            if (this.form.controls.sprintId.invalid || this.form.controls.sprintId.value == '') {
                this.form.controls.sprintId.markAsTouched();
                return
            }
        }

        this.stillLoading = true;
        const form = this.form.getRawValue();

        const uatSchedules = this.uatEditComponent.uatSchedules;

        const mrpRequestDtoList: MRPRequestDto[] =
            uatSchedules.map((schedule) => {
                return {
                    sprintName: this.getSprintName(),
                    sprintEndTime: moment(form?.endDate, this._datePlaceHolder).format(environment.dateTimeFormat),
                    uatName: schedule.uatName,
                    uatId: schedule.uatId,
                    sprintStartTime: moment(form?.startDate, this._datePlaceHolder).format(environment.dateTimeFormat),
                    uatScheduledTime: schedule?.uatScheduledDate,
                    useCaseIds: schedule?.useCaseSet.map(x => x.id),
                    softwareProductionUnits: schedule?.puSet.map((forecast => {
                        return {
                            releaseDate: forecast?.code,
                            id: forecast?.id
                        }
                    })),
                    wigId: form?.wigId ? Number(form.wigId) : undefined,
                }
            })

        const payload: CreateComplianceEntryLogDto = {
            platformId: form.platformId,
            productionProcessStageCode: form.stageCode,
            mrpRequestDto: mrpRequestDtoList
        };

        this.processComplianceService.createProductionProcessComplianceEntry({createComplianceEntryLogDto: payload})
            .subscribe({
                next: (response: NameIdPojo) => {
                    this.stillLoading = false;
                    this.showAlertMessage.emit({msg: 'Entry logged!', type: AlertType.success});
                    this.uatEditComponent.uatSchedules = [];

                    this.saveEvent.emit(response);

                    if (this.goBackAfterSave) {
                        setTimeout(() => {
                            this.helperService.back();
                        }, 1500);
                    }
                },
                error: (error: unknown) => {
                    this.stillLoading = false;
                    this.showAlertMessage.emit({msg: this.helperService.getError(error), type: AlertType.error});
                }
            })

    }

    viewProductionUnits(productionUnitPojos: Array<SoftwareProductionUnitPojo> | undefined): void {

        if (productionUnitPojos == undefined) {
            return;
        }

        const productionUnitIds = productionUnitPojos.map(x => x.id);

        this.helperService.loadModal(ViewPuDialogComponent, 'Production Units', '',
            {
                productionUnitIds: productionUnitIds,
            },
            '800px')

    }

    sprintStartDate() {
        return this.minDate;
    }

    sprintEndDate() {
        return this.maxDate;
    }

    uatDate() {
        return this.form?.controls.uatScheduledDate.value;
    }

    now() {
        return new Date();
    }

    private initUpdate(): void {
        this.form.controls.sprintType.setValue(true);
        this.fetchMRPDetails();
    }
}
