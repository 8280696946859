/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CustomerContactDto } from './customer-contact-dto';


export interface CustomerDetailsDto { 
    customerOrganisationName: string;
    platformId?: Array<number>;
    businessType: number;
    customerRegistrationNumber?: string;
    customerWebsite: string;
    stateCode: string;
    customerOperationalAddress: string;
    customerContactDtoList?: Array<CustomerContactDto>;
}

