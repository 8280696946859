/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NameEmailIdPojo } from './name-email-id-pojo';
import { WorkProductSectionDefinitionAndDataPojo } from './work-product-section-definition-and-data-pojo';
import { WorkProductDocumentStyleDto } from './work-product-document-style-dto';
import { NameIdPojo } from './name-id-pojo';
import { AttachmentPojo } from './attachment-pojo';
import { ReviewStatusPojo } from './review-status-pojo';
import { RevisionHistoryPojo } from './revision-history-pojo';


export interface WorkProductPojo { 
    id?: number;
    previousVersionId?: number;
    nextVersionId?: number;
    title?: string;
    identifier?: string;
    createdAt?: string;
    processGeneratedFor?: string;
    createdBy?: NameEmailIdPojo;
    creatorDesignation?: NameIdPojo;
    reviewStatusPojo?: ReviewStatusPojo;
    scope?: WorkProductPojo.ScopeEnum;
    completed?: boolean;
    versionNumber?: number;
    versionNumberDisplayName?: string;
    footerInfo?: string;
    definitionVersionNumber?: number;
    logo?: AttachmentPojo;
    documentStyle?: WorkProductDocumentStyleDto;
    purposeAnnotations?: Array<WorkProductPojo.PurposeAnnotationsEnum>;
    department?: NameIdPojo;
    workProductDefinition?: NameIdPojo;
    processExecutionInstance?: NameIdPojo;
    documentControlHistory?: Array<RevisionHistoryPojo>;
    documentApprovalHistory?: Array<RevisionHistoryPojo>;
    sectionDefinitions?: Array<WorkProductSectionDefinitionAndDataPojo>;
    workProductDefinitionApproverRoles?: Array<NameIdPojo>;
    selectedApprover?: NameIdPojo;
}
export namespace WorkProductPojo {
    export type ScopeEnum = 'ORGANISATION' | 'PROCESS';
    export const ScopeEnum = {
        Organisation: 'ORGANISATION' as ScopeEnum,
        Process: 'PROCESS' as ScopeEnum
    };
    export type PurposeAnnotationsEnum = 'USER_ACCEPTANCE' | 'QUALITY_ASSURANCE' | 'DOCUMENTATION';
    export const PurposeAnnotationsEnum = {
        UserAcceptance: 'USER_ACCEPTANCE' as PurposeAnnotationsEnum,
        QualityAssurance: 'QUALITY_ASSURANCE' as PurposeAnnotationsEnum,
        Documentation: 'DOCUMENTATION' as PurposeAnnotationsEnum
    };
}


