import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'underscoreToSpace'
})
export class UnderscoreToSpacePipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  transform(value: string, args?: unknown): string {
    if (!value || !value?.replace) {
      return value;
    }
    return value?.replace(/_/g, ' ');
  }
}
