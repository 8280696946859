import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Subject} from "rxjs";

@Component({

    selector: 'confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent {
    @Input() header = 'Confirmation';
    @Input() body = 'Are you sure?';
    @Input() positiveButtonText = 'Proceed';
    @Input() negativeButtonText = 'Cancel';
    @Input() dismissOnConfirm = true;
    @Input() showLoadingOnConfirmClick = true;
    @Output()
    confirm = new EventEmitter();
    cancel = new EventEmitter();
    @Input() loading = false;
    subject!: Subject<boolean>;

    constructor(public modalRef: BsModalRef) {
    }

    close(): void {
        this.modalRef.hide();
        this.cancel.emit();
        this.subject.next(false);
        this.subject.complete();
    }


    confirmButtonClicked(): void {
        if (this.showLoadingOnConfirmClick) {
            this.loading = true;
        }
        this.confirm.emit();
        this.subject.next(true);
        this.subject.complete();
        if (this.dismissOnConfirm) {
            this.modalRef.hide();
        }

    }
}
