import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {AlertType} from "../../../../extranet/report-issue/report-issue.component";
import {environment} from "../../../../../../environments/environment";

@Component({
    selector: 'app-attachment',
    templateUrl: './attachment.component.html',
    styleUrls: ['./attachment.component.scss']
})
export class AttachmentComponent implements OnChanges {


    @Input()
    public files: any[] = [];

    @Input()
    required = false;

    @Input()
    maxNumberOfDocForUpload = 5

    @Input()
    title;

    @Input()
    maxDocSize: number = 5 * 1024 * 1024

    @Output()
    alertChange: EventEmitter<{ message: string, alertType: AlertType }> = new EventEmitter<{
        message: string;
        alertType: AlertType
    }>();

    @Output() filesUploaded = new EventEmitter<string[]>();

    @Output() fileUploadedDto = new EventEmitter<any[]>();

    @Input()
    accepts = 'application/pdf, image/jpg, image/jpeg, image/png, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';


    @Input()
    acceptsText = 'PNG'

    isFileUploaded = false;

    @Input()
    previousLogoId: number;
    @Input()
    viewOnly = false;

    @Input()
    showPreview = true;

    @Output() removeFile = new EventEmitter<any>();

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['maxNumberOfDocForUpload'] && changes['maxNumberOfDocForUpload'].currentValue !== changes['maxNumberOfDocForUpload'].previousValue) {
            this.maxNumberOfDocForUpload = changes['maxNumberOfDocForUpload'].currentValue;
            console.log( changes['maxNumberOfDocForUpload'].currentValue)
        }
    }

    prepareFilesList(files: Array<any>) {
        const base64Files: string[] = [];


        for (const item of files) {
            if (this.files.length === this.maxNumberOfDocForUpload || this.maxNumberOfDocForUpload == 0) {
                this.alertChange.emit({
                    message: this.maxNumberOfDocForUpload != 0 ?
                        `You can upload a maximum of ${this.maxNumberOfDocForUpload} document`:
                        `You have reached the maximum number of file limit allowed.`,
                    alertType: AlertType.error
                });
                return;
            }

            if (item.size > this.maxDocSize) {
                this.alertChange.emit({
                    message: `Maximum document size is ${this.maxDocSize / (1024 * 1024)}MB`,
                    alertType: AlertType.error
                });
                continue;
            }

            const totalSize = this.files.reduce((acc, file) => acc + file.size, 0);
            if ((item.size + totalSize) > this.maxDocSize) {
                this.alertChange.emit({
                    message: `Maximum size of all documents must not be greater than ${this.maxDocSize / (1024 * 1024)}MB`,
                    alertType: AlertType.error
                });
                return;
            }

            const reader = new FileReader();
            reader.readAsDataURL(item);
            reader.onload = (): void => {
                const base64String = reader.result as string;
                base64Files.push(base64String.split(',')[1]);
                console.log(base64String)
                this.files.push({
                    base64Data: base64String,
                    type: this.getFileType(item),
                    fileName: this.getFileName(item)
                });

                if (base64Files.length === files.length) {
                    this.filesUploaded.emit(base64Files);
                    this.fileUploadedDto.emit(this.files);
                }
            };
        }
    }


    getFileType(file: File): string {
        const fileExtension = file?.name?.split('.')?.pop().toLowerCase();

        if (['pdf'].includes(fileExtension)) {
            return 'pdf';
        } else if (['jpg', 'jpeg', 'png'].includes(fileExtension)) {
            return 'image';
        } else if (['xls', 'xlsx'].includes(fileExtension)) {
            return 'excel';
        } else {
            return 'unknown';
        }
    }


    getFileName(file: File): string {
        return file.name;
    }


    delete(file: any): void {
        this.isFileUploaded = false;
        this.files.splice(this.files.indexOf(file), 1);
        this.fileUploadedDto.emit(this.files);
        this.removeFile.emit();
    }


    onSelect(event: { addedFiles: any; }) {
        this.isFileUploaded = true;
        this.prepareFilesList(event.addedFiles)
    }

    preview(file: any) {
        this.download(file);
        /*        const dataUrl = 'data:application/pdf;base64,' + file.base64Data;
                const downloadLink = document.createElement('a');
                downloadLink.style.display = 'none';
                downloadLink.href = dataUrl;
                downloadLink.setAttribute('download', 'Attachment');
                downloadLink.setAttribute('target', '_blank'); // Open in a new tab
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);*/

    }

    download(file: any) {
        let dataUrl: string;

        if (file?.bwBinaryDataId) {
            dataUrl = this.getFileUrl(file?.bwBinaryDataId, true)

        } else if (file?.type == 'image') {
            dataUrl = file.base64Data;
        } else {
            dataUrl = 'data:application/pdf;base64,' + file.base64Data;
        }
        const downloadLink = document.createElement('a');
        downloadLink.style.display = 'none';
        downloadLink.href = dataUrl;
        downloadLink.setAttribute('download', file?.fileName || 'Attachment');
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink)
    }

    getFileUrl(fileId: number, download = true): string {
        return download
            ? `${environment.apiBaseUrl}/files/${fileId}`
            : `${environment.apiBaseUrl}/files/${fileId}/open`;
    }

    imageUrl(id: number): string {
        return `${environment.siteUrl}/files/${id}`;
    }

    clearFiles() {
        this.files = [];
    }

    getFileSize(sizeInBytes: number): string {
        if (sizeInBytes < 1024) {
            return `${sizeInBytes} B`;
        } else if (sizeInBytes < 1024 * 1024) {
            return `${(sizeInBytes / 1024).toFixed(2)} KB`;
        } else if (sizeInBytes < 1024 * 1024 * 1024) {
            return `${(sizeInBytes / (1024 * 1024)).toFixed(2)} MB`;
        } else {
            return `${(sizeInBytes / (1024 * 1024 * 1024)).toFixed(2)} GB`;
        }
    }

}
