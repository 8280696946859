/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DocumentLinkDto { 
    relationshipType: DocumentLinkDto.RelationshipTypeEnum;
    referenceDocumentId: number;
    targetDocumentIds?: Array<number>;
}
export namespace DocumentLinkDto {
    export type RelationshipTypeEnum = 'RESPONSE' | 'RELATED';
    export const RelationshipTypeEnum = {
        Response: 'RESPONSE' as RelationshipTypeEnum,
        Related: 'RELATED' as RelationshipTypeEnum
    };
}


