/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NameIdPojo } from './name-id-pojo';


export interface ApprovalRequestPojo { 
    id?: number;
    createdAt?: string;
    createdBy?: NameIdPojo;
    requesterEntityName?: string;
    recordId?: number;
    parentEntityName?: string;
    parentRecordId?: number;
    approvalWorkflowId?: number;
    selectedApprovers?: Array<number>;
    approver?: string;
    approverDesignation?: string;
    reviewStatus?: ApprovalRequestPojo.ReviewStatusEnum;
    reviewedAt?: string;
    reviewedBy?: NameIdPojo;
    identifier?: string;
}
export namespace ApprovalRequestPojo {
    export type ReviewStatusEnum = 'APPROVED' | 'PENDING' | 'DISAPPROVED';
    export const ReviewStatusEnum = {
        Approved: 'APPROVED' as ReviewStatusEnum,
        Pending: 'PENDING' as ReviewStatusEnum,
        Disapproved: 'DISAPPROVED' as ReviewStatusEnum
    };
}


