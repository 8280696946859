/**
 * Customer Fulfillment API
 * The Customer Fulfillment API using springdoc-openapi and OpenAPI 3.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: niloeje@byteworks.com.ng
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IncidentResponseStepPojo } from './incident-response-step-pojo';
import { IncidentContactPojo } from './incident-contact-pojo';
import { IncidentNotificationRecipientPojo } from './incident-notification-recipient-pojo';
import { IncidentLogHistoryPojo } from './incident-log-history-pojo';
import { ContactInfoPojo } from './contact-info-pojo';
import { NameIdPojo } from './name-id-pojo';
import { IncidentImpactPojo } from './incident-impact-pojo';
import { PermanentResolutionMeasurePojo } from './permanent-resolution-measure-pojo';
import { PortalUserPojo } from './portal-user-pojo';


export interface IncidentLogPojo { 
    id?: number;
    incidentContact?: IncidentContactPojo;
    identifier?: string;
    description?: string;
    rootCause?: string;
    reviewer?: string;
    reasonForReview?: string;
    platform?: NameIdPojo;
    createdBy?: PortalUserPojo;
    resolutionStatus?: IncidentLogPojo.ResolutionStatusEnum;
    reviewStatus?: IncidentLogPojo.ReviewStatusEnum;
    createdAt?: string;
    occuredAt?: string;
    resolvedAt?: string;
    reviewedAt?: string;
    recipients?: Array<IncidentNotificationRecipientPojo>;
    designationsToNotify?: Set<string>;
    contactInfo?: Array<ContactInfoPojo>;
    responseSteps?: Array<IncidentResponseStepPojo>;
    resolutionMeasure?: Array<PermanentResolutionMeasurePojo>;
    incidentImpact?: Array<IncidentImpactPojo>;
    incidentLogHistory?: Array<IncidentLogHistoryPojo>;
    approverRequestId?: number;
}
export namespace IncidentLogPojo {
    export type ResolutionStatusEnum = 'PENDING' | 'IN_PROGRESS' | 'RESOLVED' | 'CANCELLED';
    export const ResolutionStatusEnum = {
        Pending: 'PENDING' as ResolutionStatusEnum,
        InProgress: 'IN_PROGRESS' as ResolutionStatusEnum,
        Resolved: 'RESOLVED' as ResolutionStatusEnum,
        Cancelled: 'CANCELLED' as ResolutionStatusEnum
    };
    export type ReviewStatusEnum = 'APPROVED' | 'PENDING' | 'DISAPPROVED';
    export const ReviewStatusEnum = {
        Approved: 'APPROVED' as ReviewStatusEnum,
        Pending: 'PENDING' as ReviewStatusEnum,
        Disapproved: 'DISAPPROVED' as ReviewStatusEnum
    };
}


